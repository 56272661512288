.room-wrapper {
  overflow: hidden;
}

.wrapper {
  padding: 0 16px;
  margin: 0 auto;
  margin-bottom: 128px;

  &-list {
    max-height: calc(100vh - 180px);
    overflow-y: auto;
  }
}

.slide {
  display: flex;
  gap: 1rem;
  transition: transform 400ms;
}

.room-list-wrapper {
  padding: 0 5px;
}

.room-element {
  display: flex;
  flex: 1 0 100%;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  height: 48px;
  padding-right: 16px;
  padding-left: 16px;
  margin: 0 auto;
  margin-bottom: 8px;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 0 15px rgb(0 0 0 / 5%);
}

.active-room-label {
  width: 73px;
  height: 20px;
  padding: 2px 12px;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  background: #5ed391;
  border-radius: 10px;
}

.button-group {
  display: flex;
  gap: 1rem;
}

.inactive-room-label {
  width: 73px;
  height: 20px;
  padding: 2px 12px;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  background: #cccccc;
  border-radius: 10px;
}

.delete-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 48px;
  padding: 14px 16px;
}

.info-component {
  text-align: center;
}

.create-room {
  position: fixed;
  bottom: 69px;
  left: 16px;
  width: calc(100% - 32px);
}

.create-room:hover {
  cursor: pointer;
  opacity: 0.8;
}

.create-room a {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: #4373d2;
  text-decoration: none !important;
}

.center-div {
  margin-top: calc(50vh - 78px);
  font-family: 'Open Sans', sans-serif;
  text-align: center;
  transform: translateY(-100%);
}

.center-div p {
  margin-top: 20px;
}

.modal {
  & h2 {
    margin: 3.5rem 3rem 2.5rem;
    font-family: 'Open Sans', sans-serif;
    text-align: center;
  }

  & .button-group {
    margin: 0 2rem 2.5rem;
  }
}

@media (width >=768px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-width: 1000px;
    max-height: 100vh;
    margin: 0 auto;

    &-list {
      max-height: calc(100vh - 192px);
    }
  }

  .room-element {
    flex: 1 1 100%;
  }

  .create-room {
    left: calc(50% - 500px);
    max-width: 1000px;
  }
}
