.button {
  display: block;
  width: 100%;
  padding: 6px;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  background: var(--button-color);
  border: none;
  border-radius: 20px;
  box-shadow: 0 0 15px rgb(0 0 0 / 5%);
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.3;
  }
}
