:root {
  --background-color: #6688cc;
  --sheet-color: #fefaee;
  --secondary-color: #c8c8c8;
  --primary-text-color: #ffffff;
  --alternative-text-color: #3e3e3e;
  --secondary-text-color: #383838;
  --button-color: #4373d2;
  --placeholder-color: #898989;
}
