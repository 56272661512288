body {
  height: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  padding: 24px 24px 30px;
  color: var(--primary-text-color);
  background-color: var(--background-color);
}

.title {
  position: sticky;
  top: 0;
  padding-top: 40px;
}

.attempt-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 265px;
  margin-top: 160px;

  & p {
    font-family: 'Open Sans', sans-serif;
    text-align: center;

    & a {
      color: var(--primary-text-color);
      text-decoration: none;
    }
  }
}

.bold {
  font-weight: 600;
}
