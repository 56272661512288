.wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.shadow {
  position: fixed;
  z-index: 20;
  width: 100%;
  height: 100vh;
  background-color: rgb(0 0 0 / 70%);
}

.content {
  position: relative;
  z-index: 100;
  padding: 16px 16px 0;
  background: var(--sheet-color);
  border-radius: 20px;
}

.close {
  position: absolute;
  top: 16px;
  right: 16px;
}

@media (width >= 768px) {
  .content {
    max-width: 350px;
    margin: 0 auto;
  }
}
