.wrapper {
  padding: 0 16px;
}

.players-count {
  font-family: 'Open Sans', sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 65px;
  color: var(--primary-text-color);
  text-align: center;
}

.players-count-label {
  margin-top: -8px;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  color: var(--primary-text-color);
  text-align: center;
}

.guessed-sheet {
  padding: 16px 16px 24px;
  margin: 20px 0 24px;
  background: var(--primary-text-color);
  border-radius: 20px;
}

.section-title {
  padding-left: 16px;
  margin-bottom: 4px;
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: var(--alternative-text-color);
}

.stats-row {
  display: flex;
  justify-content: space-between;
}

.stats-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  color: var(--alternative-text-color);
}

.progress-wrapper {
  margin-top: 12px;
  overflow: hidden;
  background: #ffe4c5;
  border-radius: 8px;
}

.progress-path {
  min-width: 32px;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  color: var(--alternative-text-color);
  text-align: center;
  background: #ffce71;
  border-radius: 8px;
}

.attempts {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px;
  height: 104px;
  margin-bottom: 24px;
}

.attempt {
  padding: 20px;
  background: var(--primary-text-color);
  border-radius: 20px;
  box-shadow: 0 0 15px rgb(0 0 0 / 5%);
}

.attempt-stat {
  margin-bottom: 8px;
  font-family: Futura, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px;
  color: var(--alternative-text-color);
  text-align: center;
}

.attempt-label {
  font-family: Futura, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  color: var(--alternative-text-color);
  text-align: center;
}

.timer {
  margin-bottom: 60px;
  font-family: 'SF Pro Display', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  color: var(--alternative-text-color);
  text-align: center;
}

.guessed-word {
  position: relative;
  width: 100%;
  padding: 12px 16px;
  margin-bottom: 40px;
  background: var(--primary-text-color);
  filter: drop-shadow(0 0 15px rgb(0 0 0 / 5%));
  border: none;
  border-radius: 20px;
}

.guessed-word-text {
  font-family: 'SF Pro Display', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  text-transform: lowercase;
}

.icon {
  position: absolute;
  top: 0;
  right: 16px;
  bottom: 0;
  margin: auto;
}

.delete-button {
  box-shadow: none;
}

.modal {
  & h2 {
    margin: 3.5rem 3rem 2.5rem;
    font-family: 'Open Sans', sans-serif;
    text-align: center;
  }

  & .button-group {
    display: flex;
    gap: 1rem;
    margin: 0 2rem 2.5rem;
  }
}

.modal-content {
  display: grid;
  justify-items: center;
}

.modal-title {
  margin: 24px 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  color: var(--alternative-text-color);
  text-align: center;
}

.modal-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: var(--alternative-text-color);
  text-align: center;
}

.button {
  margin: 20px 0 16px;
}

@media (width >= 768px) {
  .wrapper {
    position: static;
    max-width: 1000px;
    margin: 0 auto;
  }
}
