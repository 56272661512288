.wrapper {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: calc(100% - 76px);
  padding: 0 16px 142px;
  overflow-y: auto;
  background: var(--sheet-color);
  border-radius: 30px 30px 0 0;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.edge {
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 5px;
}
