.wrapper {
  position: relative;
}

.input {
  display: block;
  width: 100%;
  padding: 6px 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  color: var(--alternative-text-color);
  border: none;
  border-bottom: 1px solid var(--alternative-text-color);
  outline: none;

  &::placeholder {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    color: var(--placeholder-color);
  }
}

.icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
