.countdown-wrapper {
  z-index: 9999;

  & p {
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #ffffff;
    text-align: center;
  }
}
