.info-component {
  text-align: center;
}

.center-div {
  margin-top: calc(50vh - 78px);
  font-family: 'Open Sans', sans-serif;
  text-align: center;
  transform: translateY(-100%);
}

.center-div p {
  margin-top: 20px;
}

.create-room {
  position: fixed;
  bottom: 69px;
  left: 16px;
  width: calc(100% - 32px);
}

.create-room:hover {
  cursor: pointer;
  opacity: 0.8;
}

.create-room a {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: #4373d2;
  text-decoration: none !important;
}

.bold {
  font-weight: bold;
}

@media (width >= 768px) {
  .wrapper {
    position: static;
    max-width: 1000px;
    margin: 0 auto;
  }

  .room-element {
    flex: 1 1 100%;
  }

  .create-room {
    left: calc(50% - 500px);
    width: 1000px;
  }
}
