.wrapper {
  padding: 0 16px;
}

.title {
  margin-bottom: 34px;
  font-family: Futura, sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 37px;
  color: var(--alternative-text-color);
  text-align: center;
}

.sheet {
  padding: 24px 30px 18px;
  background: var(--primary-text-color);
  border-radius: 20px;
  box-shadow: 0 0 15px rgb(0 0 0 / 5%);
}

.colors {
  display: flex;
  justify-content: space-between;
  margin: 44px 0;
  list-style: none;
}

.color {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.loader {
  margin-top: 18px;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  color: var(--alternative-text-color);
  text-align: center;
}

.loader-transparent {
  color: transparent;
}

.rules {
  display: grid;
  grid-gap: 40px;
  margin: 40px 0;
}

.rule {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 12px;
  align-items: center;
}

.rule-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  color: var(--alternative-text-color);
}

.input {
  margin-bottom: 10px;
}

.complexity-group {
  margin-top: 24px;
}

// .ChoiceGroup {
//   margin-top: 8px;
// }

@media (width >=768px) {
  .wrapper {
    position: static;
    max-width: 1000px;
    margin: 0 auto;
  }

  .loader {
    font-size: 16px;
  }

  .rule-text {
    font-size: 16px;
  }
}
