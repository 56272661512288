.wrapper {
  position: absolute;
  inset: 0;
  background: var(--sheet-color);
}

.sidebar-cross {
  position: absolute;
  right: 0;
  cursor: pointer;
}

.player-name {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  color: var(--text);
}

.sidebar-option {
  display: grid;
  grid-template-columns: 32px 1fr;
  align-items: center;
  margin-top: 24px;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  color: var(--alternative-text-color);
  text-decoration: none;
  cursor: pointer;

  svg {
    height: 24px;
  }
}

.profile-auth {
  display: grid;
  grid-template: '1fr 1fr';
  grid-gap: 15px;
  margin-top: 15px;
}

.navbar-section {
  margin-top: 100px;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.bottom-navbar {
  position: absolute;
  right: 16px;
  bottom: 30px;
  left: 16px;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
}

@media (width >= 768px) {
  .wrapper {
    position: static;
    max-width: 400px;
    padding-bottom: 90px;
    margin: 20px auto 0;
    background: none !important;
  }
}
