.wrapper {
  display: grid;
  grid-gap: 24px;
  justify-items: center;
}

.message {
  font-family: 'Open Sans', sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 38px;
  color: var(--alternative-text-color);
  text-align: center;
}

.description {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  color: var(--alternative-text-color);
}

.blue-text {
  font-weight: 600;
  color: var(--button-color);
}

.stats {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  color: var(--alternative-text-color);
  text-align: center;
}

.modal-button {
  width: 50%;
  margin-bottom: 2rem;
}
