.wrapper {
  padding: 0 16px;
}

.sheet {
  padding: 24px 24px 18px;
  background: var(--primary-text-color);
  border-radius: 20px;
  box-shadow: 0 0 15px rgb(0 0 0 / 5%);
}

.title {
  margin-bottom: 34px;
  font-family: Futura, sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 37px;
  color: var(--alternative-text-color);
  text-align: center;
}

.suffix {
  margin-top: 24px;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  color: var(--alternative-text-color);
  text-align: center;
}

.button {
  margin-top: 70px;
}

.link {
  color: var(--button-color);
  text-decoration: none;
}

.loader {
  margin-top: 18px;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  color: var(--alternative-text-color);
  text-align: center;
}

.loader-hidden {
  color: transparent;
}

.input {
  margin-bottom: 40px;
}

@media (width >= 768px) {
  .wrapper {
    position: static;
    max-width: 1000px;
    margin: 0 auto;
  }
}
