.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 24px 12px;
  padding-bottom: 17px;
}

.heading {
  position: absolute;
  right: 0;
  left: 0;
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.title {
  font-family: Futura, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: var(--primary-text-color);
  text-transform: uppercase;
}

.subtitle {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: var(--primary-text-color);
}

.icon {
  z-index: 2;
  height: 24px;
  fill: var(--primary-text-color);
}

@media (width >= 768px) {
  .title {
    font-size: 20px;
    color: var(--alternative-text-color);
  }

  .icon {
    fill: var(--alternative-text-color);
  }

  .subtitle {
    font-size: 14px;
    color: var(--alternative-text-color);
  }
}
