.title {
  margin-bottom: 30px;
  font-family: 'Open Sans', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  color: var(--alternative-text-color);
}

.list {
  display: grid;
  grid-gap: 40px;
  margin-bottom: 30px;
  list-style: none;
}

.item {
  display: grid;
  grid-template-columns: 30px 1fr;
  grid-gap: 12px;
  align-items: center;
}

.text {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 19px;
  color: var(--alternative-text-color);
}

.centered {
  text-align: center;
}

@media (width >= 768px) {
  .title {
    margin-top: 20px;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
  }

  .text {
    font-size: 16px;
  }
}
