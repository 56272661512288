.words {
  display: grid;
  grid-gap: 8px;
}

.loader {
  padding-left: 16px;
  margin: 10px 0 30px;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: var(--alternative-text-color);
}

.last-guessed-word {
  margin-bottom: 24px;
}

.input-form {
  position: relative;
  padding-bottom: 21px;
  margin-bottom: 21px;
  border-radius: 0 0 20px 20px;
}

.input {
  position: absolute;
  display: block;
  width: calc(100% - 32px);
  padding: 10px 36px 10px 20px;
  margin-left: 16px;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: var(--secondary-text-color);
  background: #ffffff;
  border: none;
  border-radius: 20px;
  outline: none;
  box-shadow: 0 0 15px rgba(0 0 0 / 5%);
}

.mobile-words-wrapper {
  height: calc(100vh - 76px);
  padding: 0 16px 142px;
  padding-top: 16px;
  overflow-y: auto;
  border-radius: 30px 30px 0 0;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.clear-icon {
  position: absolute;
  top: 12px;
  right: 32px;
  margin: auto;
}

.support-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  margin-top: 1rem;
  color: black;
  background-color: #dff0d8;

  a {
    color: #f15f2c;
    text-decoration: none;
  }
}

@media (width >= 768px) {
  .pc-words-wrapper {
    padding-top: 10px;
  }

  .last-guessed-word {
    margin-bottom: 19px;
  }
}

@media (width >= 768px) {
  .input {
    position: relative;
    width: 100% !important;
    margin-top: 24px;
    margin-left: 0;
  }

  .input-form {
    padding-bottom: 0;
    background: none !important;
  }

  .clear-icon {
    top: 0;
    right: 16px;
    bottom: 0;
  }
}
