.wrapper {
  position: relative;
  display: grid;
  grid-template-areas: 'l c r';
  grid-template-columns: 24px 1fr 24px;
  align-items: center;
  padding: 24px 24px 30px;
}

.back {
  fill: var(--sheet-color);
}

.back-dark {
  fill: var(--alternative-text-color);
}

.title {
  font-family: Futura, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: var(--primary-text-color);
  text-align: center;
  text-transform: uppercase;
}

.title-dark {
  color: var(--alternative-text-color);
}

.subtitle {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: var(--primary-text-color);
}

.backdrop {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: -1;
  height: 200px;
  border-radius: 0 0 20px 20px;
}
