.wrapper {
  position: relative;
  height: 40px;
  background: var(--primary-text-color);
  border-radius: 20px;
  box-shadow: 0 0 15px rgb(0 0 0 / 5%);
}

.bordered {
  border: 2px solid var(--alternative-text-color);
}

.progress {
  display: block;
  min-width: 40px;
  height: 100%;
  border-radius: 20px;
}

.label {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 16px;
  height: fit-content;
  margin: auto;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: var(--secondary-text-color);
}

.value {
  position: absolute;
  top: 0;
  right: 18px;
  bottom: 0;
  height: fit-content;
  margin: auto;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  color: var(--secondary-text-color);
  text-align: right;
}

.green {
  background: #7ec88c;
}

.yellow {
  background: #f6bc79;
}

.red {
  background: #ff8f71;
}
